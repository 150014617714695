
import CanvasContent from './canvas-content'
import debounce from 'lodash/debounce'
import dimensions from '~/assets/vars/dimensions'
import {detect} from 'detect-browser'

export default
	props: block: Object

	components: {CanvasContent}

	data: ->
		scrollProgress: 0 # The animation scroll progress
		width: 0 # The width of the canvas
		height: 0 # The height of the canvas
		initialMaskWidth: 140 # Initial width of the mask
		initialMaskHeight: 82 # Initial height of the mask
		initialMaskRoundness: 82 / 2 # Start as a pill
		initialMaskRotation: -45 # Initial rotation of the mask
		maskWidth: 140 # Current mask width
		maskHeight: 82 # Current mask Height
		maskRotation: -45 # Current mask rotation
		maskRoundness: 82 / 2  # Current mask roundness
		randomValue: null
		maskData:
			maskWidth: 140 # Current mask width
			maskHeight: 82 # Current mask Height
			maskRotation: -45 # Current mask rotation
			maskRoundness: 82 / 2  # Current mask roundness

	computed:
		# Final width of the mask will be as large as the greatest component side
		finalMaskWidth: -> Math.max @width, @height

		# Final width of the mask will be as large as the smalles component side
		finalMaskHeight: -> Math.min @width, @height

		# The rotation will align the rectangle so that the largest dimension of the rectangle
		# matches the largest dimension of the component
		finalMaskRotation: ->
			if @width > @height then 0 else -90

		# Hardcoded mask roundness
		finalMaskRoundness: -> 32

		# Calculate frame load increment
		frameLoadIncrement: ->
			return @block.frameLoadIncrement unless @block.frameLoadIncrementMobile
			return @block.frameLoadIncrement unless @$store.state.layout.viewport.width <= 768
			return @block.frameLoadIncrementMobile || @block.frameLoadIncrement

		# The component itself is as tall as the viewport
		styles: ->
			height: @elHeight

		# The current internal client height
		# this is different than viewportHeight because it's not recalculated
		# when location bar expands/retracts on ios safari
		clientHeight: ->
			return @$store.state.layout.viewport.clientHeight unless @isMobileFirefoxOrGSA
			screenHeight = @$store.state.layout.viewport.screenHeight
			return screenHeight - 80

		# User agent from request header
		userAgent: -> @$store.state.layout.userAgent

		isMobile: -> @browser?.os == 'Android OS' or @browser?.os == 'iOS'

		# Check if is mobile firefox or gsa to treat height differently
		isMobileFirefoxOrGSA: ->
			return unless @isMobile
			return true if ['firefox', 'fxios'].includes(@browser.name) or /GSA\/\d+\.\d+\.\d+/.test(@userAgent)

		# How far we'll have to scroll in order to go through the entire animation
		scrollEnd: ->
			return switch @block.animationDuration
				when 'long' then "+=#{@clientHeight * 3}"
				when 'extra-long' then "+=#{@clientHeight * 6}"
				when 'xx-long' then "+=#{@clientHeight * 9}"
				when 'xxx-long' then "+=#{@clientHeight * 12}"
				else "bottom bottom"

		elHeight: -> switch @block.animationDuration
			when 'long' then "#{@clientHeight * 4}px"
			when 'extra-long' then "#{@clientHeight * 7}px"
			when 'xx-long' then "#{@clientHeight * 10}px"
			when 'xxx-long' then "#{@clientHeight * 13}px"
			else "#{@clientHeight * 2.5}px"

		isiOS: -> @browser?.os == 'iOS'

		classes: -> [
			"animation-duration-#{@block.animationDuration}"
			"is-ios" if @isiOS
			"is-mobile-firefox-or-gsa" if @isMobileFirefoxOrGSA
			# "is-mobile-firefox-or-gsa"
		]

		browser: -> detect(@userAgent)

	# Set initial dimensions, set listeners, and setup motion
	mounted: ->
		@setDimensions()
		window.addEventListener 'resize', @onResize
		@$nextTick =>
			@motionSetup()

		@isiOS =
			[
				'iPad Simulator',
				'iPhone Simulator',
				'iPod Simulator',
				'iPad',
				'iPhone',
				'iPod'
			].includes(navigator.platform) or
			(navigator.userAgent.includes("Mac") and "ontouchend" of document)

	# Clean up stuff
	beforeDestroy: ->
		window.removeEventListener 'resize', @onResize
		@pinningScrollTrigger?.kill()
		@maskScrollTrigger?.kill()

	methods:
		setDimensions: ->
			@width = @$refs.pinned.offsetWidth
			headerSize = if window.innerWidth < 768 then parseInt(dimensions['mobile-header-h'])  + 32 * 2 else parseInt(dimensions['header-h'])
			@height = @clientHeight - headerSize

		onResizeActions: ->
			@setDimensions()
			@maskTimeline?.kill()
			@setMaskTimeline()

		onResize: debounce (-> @onResizeActions()), 1

		setMaskTimeline: ->
			@maskTimeline = @$gsap.timeline()
				.pause()
				.fromTo(
					@,
					{
						maskWidth: @initialMaskWidth,
						maskHeight: @initialMaskHeight,
						maskRoundness: @initialMaskRoundness,
						maskRotation: @initialMaskRotation
					},
					{
						maskWidth: () => @finalMaskWidth,
						maskHeight: () => @finalMaskHeight,
						maskRoundness: () => @finalMaskRoundness,
						maskRotation: () => @finalMaskRotation,
						ease: 'power1.in'
						scrollTrigger:
							trigger: @$el
							start: 'top top'
							end: () => "+=#{@clientHeight}"
					},
				)

		motionSetup: ->
			# Pin the element to the viewport, so animation is always in focus
			@pinningScrollTrigger = @$ScrollTrigger.create
				trigger: @$el
				pin: @$refs.pinned
				start: 'top top'
				end: @scrollEnd
				onUpdate: ({ progress }) =>
					@scrollProgress = progress

			@setMaskTimeline()

			@$ScrollTrigger.refresh();

