
import debounce from 'lodash/debounce'
import TextOverlay from './text-overlay'
import FinalCta from './final-cta'

endTrigger = 1
nativeWidth = 1920
nativeHeight = 1080

export default
	props:
		scrollProgress: Number
		uniqueFrames: Number
		filePath: String
		filePathPortrait: String
		fileExtension: String
		frameLoadIncrement: Number|String
		lastFrameHangCount: Number
		copyAndCtaRevealTime: Number
		copyToBeRevealed: String
		buttonsToBeRevealed: Array
		canvasWidth: Number
		canvasHeight: Number
		maskWidth: Number
		maskHeight: Number
		maskRoundness: Number
		maskRotation: Number
		startTrigger: Number
		textOverlayTimeline: Array

	components: { TextOverlay, FinalCta }

	data: ->
		width: null
		height: null
		frames: []
		loadedFrames: []
		framesLoaded: 0

	computed:
		# currentHighlightedText: ->
		# 	return if !@hasHighlightedSubtext

		lastFrame: -> @uniqueFrames + @lastFrameHangCount

		scrubProgress: ->
			perc = (@scrollProgress - @startTrigger) / (endTrigger - @startTrigger)
			Math.max 0, perc

		currentFrame: -> Math.round @scrubProgress * (@frames.length - 1)

		loadProgress: ->
			return 0 unless @frames.length
			@framesLoaded / @frames.length

		loadComplete: -> @loadProgress == 1

		canvasArea: -> @canvasWidth * @canvasHeight

		isLandscape: -> (@canvasWidth/@canvasHeight) > 1

		computedFilePath: -> if @isLandscape then @filePath else (@filePathProtrait || @filePath)

		imageHeight: -> if @isLandscape then (9/16) * @canvasWidth else (16/9) * @canvasWidth

		imageWidth: -> @canvasWidth

		viewportWidth: -> @$store.state.layout.viewport.width

		# The center of the canvas
		maskX: ->
			return unless !!@maskWidth and !!@canvasWidth
			( @canvasWidth - @maskWidth ) / 2

		maskY: ->
			return unless !!@maskHeight and !!@canvasHeight
			( @canvasHeight - @maskHeight ) / 2

		maskStyle: ->
			width: "#{@maskWidth}px"
			height: "#{@maskHeight}px"
			top: "#{@maskY}px"
			left: "#{@maskX}px"
			transform: "rotate(#{@maskRotation}deg)"
			"border-radius": "#{@maskRoundness}px"

		canvasStyle: ->
			transform: "translate(#{- @maskX}px, #{- @maskY}px) rotate(#{-@maskRotation}deg)"
			top: "0"
			left: "0"
			width: "#{@canvasWidth}px"
			height: "#{@canvasHeight}px"

	watch:
		# Render the closest frame to the current progress
		currentFrame: -> @drawCurrentFrame()

		loadComplete: (complete) -> @drawCurrentFrame() if complete

		canvasArea: ->
			@initCanvas()
			@loadFrames()

	methods:
		# Round a value and snap to the nearest interval
		intervalRound: (value, interval = 200) ->
			interval * Math.ceil value / interval

		# Setup the canvas
		initCanvas: ->
			return unless @$refs.canvas
			@$refs.canvas.width = @canvasWidth
			@$refs.canvas.height = @canvasHeight
			@canvas = @$refs.canvas.getContext '2d'

		# Load all of the frames all at once and let browser sort it out
		loadFrames: ->
			@frames = []
			@framesLoaded = 0
			for index in [0..@lastFrame] by @frameLoadIncrement
				img = new Image
				img.onload = => @framesLoaded++
				img.src = @imgSrc index
				@frames.push img

		# Make the src URL at a given index
		# Images uploaded to cloud.digitalocean.com/spaces/nuro
		imgSrc: (index) ->
			num = @$padNum Math.min(index, @uniqueFrames-1), 5
			"https://nuro.imgix.net/#{@computedFilePath}-#{num}.#{@fileExtension}?\
				w=#{@canvasWidth}&h=#{@canvasHeight}&fit=crop&ar=#{@canvasWidth}:#{@canvasHeight}&auto=format"

		isiOS: ->
			[
				'iPad Simulator',
				'iPhone Simulator',
				'iPod Simulator',
				'iPad',
				'iPhone',
				'iPod'
			].includes(navigator.platform) or
			(navigator.userAgent.includes("Mac") and "ontouchend" of document)

		# Draw the current frame to the canvas
		drawCurrentFrame: ->
			return unless @canvas
			currentImage = @frames[@currentFrame]

			# Check if the image is in a broken state
			return if currentImage.naturalWidth == 0 or currentImage.naturalHeight == 0

			# Clear the canvas and draw the image
			@canvas.clearRect 0, 0, @canvasWidth, @canvasHeight

			@canvas.drawImage currentImage, 0, 0, @canvasWidth, @canvasHeight, 0, 0, @canvasWidth, @canvasHeight
